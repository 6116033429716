import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { CategoryService } from '@services/category/category.service';

export const restrictIfCategoryNotExist: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const service = inject(CategoryService);
  const router = inject(Router);

  if (!service.isCategoriesExist) {
    router.navigate(['/', 'no-categories']);
  }

  return service.isCategoriesExist;
};

export const restrictIfCategoryExist: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const service = inject(CategoryService);
  const router = inject(Router);

  if (service.isCategoriesExist) {
    router.navigate(['/']);
  }

  return !service.isCategoriesExist;
};
